var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "기본정보" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable,
                            expression: "editable",
                          },
                        ],
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.chem,
                          mappingType: _vm.mappingType,
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveInfo,
                          btnCallback: _vm.saveCallback,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                  [
                    _c("c-text", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        label: "화학물질명(ENG)",
                        name: "chemNmEn",
                      },
                      model: {
                        value: _vm.chem.chemNmEn,
                        callback: function ($$v) {
                          _vm.$set(_vm.chem, "chemNmEn", $$v)
                        },
                        expression: "chem.chemNmEn",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        label: "화학물질명(KOR)",
                        name: "chemNmKr",
                      },
                      model: {
                        value: _vm.chem.chemNmKr,
                        callback: function ($$v) {
                          _vm.$set(_vm.chem, "chemNmKr", $$v)
                        },
                        expression: "chem.chemNmKr",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
                  [
                    _c("c-text", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        label: "CAS No.",
                        name: "casNo",
                      },
                      model: {
                        value: _vm.chem.casNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.chem, "casNo", $$v)
                        },
                        expression: "chem.casNo",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        label: "화학식",
                        name: "molecularFormula",
                      },
                      model: {
                        value: _vm.chem.molecularFormula,
                        callback: function ($$v) {
                          _vm.$set(_vm.chem, "molecularFormula", $$v)
                        },
                        expression: "chem.molecularFormula",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable: _vm.editable,
                        isFlag: true,
                        label: "규제물질 대상여부",
                        name: "regulFlag",
                      },
                      model: {
                        value: _vm.chem.regulFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.chem, "regulFlag", $$v)
                        },
                        expression: "chem.regulFlag",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-tab", {
        staticClass: "q-mt-sm",
        attrs: {
          type: "tabcard",
          align: "left",
          tabItems: _vm.tabItems,
          inlineLabel: true,
          dense: true,
          height: _vm.tabHeight,
        },
        on: {
          "update:tabItems": function ($event) {
            _vm.tabItems = $event
          },
          "update:tab-items": function ($event) {
            _vm.tabItems = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: { chem: _vm.chem, contentHeight: _vm.contentHeight },
                  on: {
                    "update:chem": function ($event) {
                      _vm.chem = $event
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }